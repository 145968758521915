.pagination {
  .disabled :not(a) {
    color: gray;
  }

  @include clearfix();
  float: left;
  margin-top: 1em;
  width: 100%;
  display: flex;

  ul {
    margin: 0 auto 0 auto;
    padding: 0;
    list-style-type: none;
    font-family: $sans-serif;
  }

  li {
    display: block;
    float: left;
    margin-left: -1px;

    a {
      display: block;
      margin-bottom: 0.25em;
      padding: 0.5em 1em;
      font-family: $sans-serif;
      font-size: 14px;
      font-weight: bold;
      line-height: 1.5;
      text-align: center;
      text-decoration: none;
      color: lightgray;
      border: 1px solid mix(#000, $border-color, 25%);
      border-radius: 0;

      &:hover {
        color: $link-color-hover;
      }

      &.current,
      &.current.disabled {
        color: gray;
        background: $primary-color;
      }

      &.disabled {
        color: gray;
        pointer-events: none;
        cursor: not-allowed;
      }
    }

    &:first-child {
      margin-left: 0;

      a {
        border-top-left-radius: $border-radius;
        border-bottom-left-radius: $border-radius;
      }
    }

    &:last-child {
      a {
        border-top-right-radius: $border-radius;
        border-bottom-right-radius: $border-radius;
      }
    }
  }

  /* next/previous buttons */
  &--pager {
    display: block;
    padding: 1em 2em;
    float: left;
    width: 50%;
    font-family: $sans-serif;
    font-size: $type-size-5;
    font-weight: bold;
    text-align: center;
    text-decoration: none;
    color: $muted-text-color;
    border: 1px solid mix(#000, $border-color, 25%);
    border-radius: $border-radius;

    &:hover {
      @include yiq-contrasted($muted-text-color);
    }

    &:first-child {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }

    &:last-child {
      margin-left: -1px;
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    &.disabled {
      color: gray;
      pointer-events: none;
      cursor: not-allowed;
    }
  }
}

.page__content + .pagination,
.page__meta + .pagination,
.page__share + .pagination,
.page__comments + .pagination {
  margin-top: 2em;
  padding-top: 2em;
  border-top: 1px solid $border-color;
}
