.topmenu {
  display: flex;
}
.topmenu_left {
  flex: 20%;
}
.topmenu_center {
  flex: 60%;
}
.topmenu_right {
  flex: 20%;
}

.topmenu_right audio {
  max-height: 1.5em;
  margin-top: 0.4em;
}
.topmenu_right img {
  max-height: 1.5em;
}
// chrome
@media screen and (-webkit-min-device-pixel-ratio:0) and (min-resolution: .001dpcm) {
  audio {
    filter: invert(1);
    background: white;
  }
}
// firefox
@-moz-document url-prefix() {
  audio {
    filter: none;
    background: none;
  }
}
