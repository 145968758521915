@import 'variables';
@import 'mixins';
@import 'pagination';

* {
  background-color: black;
}

body {
  font-family: -apple-system, BlinkMacSystemFont, "helvetica neue", helvetica, roboto, noto, "segoe ui", arial, sans-serif;
}

:not(a) {
  color: white;
}

a {
  color: #037bff;
  text-decoration: none;
}

a:hover  {
  text-decoration: underline;
}
a:visited {
  color: #800080;
}

.header {
  width: 100%;
  height: auto;
}

.header-left {
  float: left;
}
.header-right {
  float: right;
}

.leftmenu_header {
  text-align: center;
}
#quotebox {
  text-align: left;
}

.mainflex {
  display: flex;
}

.leftmenu {
  flex: 15%;
}
.maincontent {
  flex: 70%;
}
.maincontent_noright {
  flex: 85%;
}
.rightmenu {
  flex: 15%;
}

.alignleft {
  float: left;
}
.alignright {
  float: right;
}

.topmenu {
  text-align: center;
}

.borderimg {
  border: 5px solid transparent;
  padding: 15px;
  margin: 5px;
  border-image: url(/assets/images/header/lair_logol.jpg) 10 round;
}

.center-image {
  margin: 0 auto;
  display: block;
}

@import 'topmenu';
@import 'rightmenu';
@import 'leftmenu';
