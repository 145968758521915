ul.leftmenu li.search {
  background: url("/assets/images/icons/search.png") left center no-repeat;
  background-size: contain;
}
ul.leftmenu li.news {
  background: url("/assets/images/icons/news.png") left center no-repeat;
  background-size: contain;
}
ul.leftmenu li.subj {
  background: url("/assets/images/icons/subj.png") left center no-repeat;
  background-size: contain;
}
ul.leftmenu li.files {
  background: url("/assets/images/icons/files.png") left center no-repeat;
  background-size: contain;
}
ul.leftmenu li.authors {
  background: url("/assets/images/icons/authors.png") left center no-repeat;
  background-size: contain;
}
ul.leftmenu li.about {
  background: url("/assets/images/icons/about.png") left center no-repeat;
  background-size: contain;
}
