@import "main";

$link_color: #037bff;
$link_color_visited: purple;

.left {
  text-align: left;
}
.right {
  text-align: right;
}
.center {
  text-align: center;
}

.float-right {
  float: right;
}
.float-left {
  float: left;
}

.center-image {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.center-image img {
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.right-image {
  float: right;
  margin-right: 1em;
  margin-left: 5px;
  margin-top: -1.5em;
  margin-bottom: 4px;
  max-height: 100px;
  max-width: 100px;
}

.subject-image {
  float: left;
  margin-right: 1em;
  max-height: 100px;
  max-width: 100px;
}

.subject-list {
  list-style: none;
}

ul.download-list li {
  list-style-image: url("/assets/images/downloads/icon_folder.png");
}
ul.download-list li span {
  display: block;
  margin-left: 0.5em;
}

ul.navigation-list li {
  list-style: none;
  padding-left: 25px;
  margin: 0 0 0 -20px;
}
ul.navigation-list li:not(:last-child) {
  margin-bottom: 10px;
}

ul.mainlist > li {
  list-style: none;
  padding-left: 25px;
  margin: 0 0 0 -20px;
  background: url("/assets/images/downloads/icon_folder.png") 0 2px no-repeat;
}

.index-avatar {
  max-height: 26px;
  vertical-align: middle;
  margin-right: 5px;
  border-radius: 3px;
}
.post-avatar {
  max-height: 52px;
  vertical-align: middle;
  border-radius: 3px;
}
.subject-avatar {
  max-height: 39px;
  vertical-align: middle;
  margin-left: 5px;
  border-radius: 3px;
}
.article-avatar {
  vertical-align: middle;
}
.author-list-avatar {
  max-height: 42px;
  vertical-align: middle;
  border-radius: 3px;
  margin-right: 4px;
}
.author-page-avatar {
  margin-top: 20px;
}

.max-width-600 img { max-width: 600px; }

.banner-img {
  max-width: 88px;
  width: 88px;
}

ul.subject {
  overflow: hidden;
}

.clearfix {
  clear: both;
}

.topmenu h4 {
  margin-top: 10px;
  margin-bottom: 10px;
}

.article-subject {
  float: right;
  padding-right: 1em;
}
.subject-padded {
  padding-left: calc(1em + 100px);
}

ul#search-results > li {
  list-style: none;
  padding-left: 25px;
  margin: 0 0 0 -20px;
  background: url("/assets/images/downloads/icon_folder.png") 0 2px no-repeat;
}
ul#search-results > li > a > h3 {
  color: $link_color;
}
ul#search-results > li > a:visited > h3 {
  color: $link_color_visited;
}

@import 'orphus-orig.css';
